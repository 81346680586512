<template>
  <Xlayout>
    <Xstep :step="2" />

    <div v-if="questionnaire.description" :class="$style.tip">
      {{ questionnaire.description }}
    </div>

    <div :class="$style.title">
      <h6>填写问卷</h6>
      <p>请填写以下问卷信息</p>
    </div>

    <van-tabs
      :class="$style.questions"
      ref="questionsTab"
      v-model="active"
      @click="handlerClickTab"
      @change="handlerChangeTab"
    >
      <template v-for="(question, index) in questions">
        <van-tab
          :key="index"
          :class="$style.question"
          :title="`第${index + 1}题`"
          :name="index"
        >
          <h6 :class="$style.content">
            {{ `${index + 1}、${question.content}` }}
          </h6>

          <question
            v-model="questionAnswers[index]"
            :index="index + 1"
            :question="question"
            @change="handlerChange"
          />
        </van-tab>
      </template>
    </van-tabs>

    <van-row
      :class="$style.toolbar"
      v-if="active > 0"
      type="flex"
      justify="space-around"
    >
      <van-col :class="$style.checkedText" span="8">
        <Xbutton :loading="isLoading" @click="handleClickPrevious"
          >上一步</Xbutton
        >
      </van-col>
      <van-col :class="$style.checkedText" span="8">
        <Xbutton :loading="isLoading" @click="handleClickNext">下一步</Xbutton>
      </van-col>
    </van-row>

    <div v-else :class="$style.toolbar">
      <Xbutton :loading="isLoading" @click="handleClickNext">下一步</Xbutton>
    </div>
  </Xlayout>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { Toast } from "vant";

import Question from "../components/question/question.vue";
import Xlayout from "../components/layout.vue";
import Xstep from "../components/step.vue";
import Xbutton from "../components/button.vue";

import QuestionnaireService from "../service/questionnaire";

import UserVisitRecordService from "../service/user-visit-record";
import UserQuestionAnswerService from "../service/user-question-answers";

export default {
  components: { Question, Xlayout, Xstep, Xbutton },
  computed: {
    ...mapState({
      application: (state) => state.application,
      account: (state) => state.account,
      user: (state) => state.user,
      exhibition: (state) => state.exhibition,
      businessCard: (state) => state.businessCard,
      visitRecord: (state) => state.visitRecord,
      userQuestionnaireId: (state) =>
        state.application.properties.userQuestionnaireId,
      questionnaireId: (state) => state.application.properties.questionnaireId,
    }),
  },
  data() {
    return {
      isLoading: false,
      active: 0,
      userQuestionnaire: {},
      questionnaire: {},
      questions: [],
      questionAnswers: [],
    };
  },
  methods: {
    ...mapActions({
      getVisitRecord: "getVisitRecord",
    }),
    async handlerChangeTab(name) {
      this.active = name;
    },
    async handlerClickTab(name) {
      this.active = name;
    },
    handlerChange(questionAnswer) {

      this.questionAnswers[questionAnswer.index - 1] = questionAnswer;
    },
    async handleClickPrevious() {
      this.active = this.active - 1;
      this.$refs.questionsTab.scrollTo(this.active);
    },
    async handleClickNext() {
      const active = this.active;

      if (this.questions[active].required) {
        if (
          !this.questionAnswers ||
          this.questionAnswers.length === 0 ||
          !this.questionAnswers[active] ||
          this.questionAnswers[active].options.length === 0
        ) {
          Toast({
            type: "fail",
            message: `第${this.active + 1}题为必选题`,
          });

          return;
        }
      }

      if (active + 1 < this.questions.length) {
        this.active = active + 1;
        this.$refs.questionsTab.scrollTo(this.active);
      } else {
        try {
          this.isLoading = true;

          console.log("提交");

          console.log(this.questionAnswers);

          await this.addQuestionAwswers();

          window.$router.push("/card");
        } catch (error) {
          console.log(error);
          Toast({
            type: "fail",
            message: error.response
              ? error.response.data.message
              : error.message,
          });
          this.isLoading = false;
        }
      }
    },
    async addQuestionAwswers() {
      for (const questionIndex in this.questions) {
        if (!this.questionAnswers[questionIndex]) {
          if (this.questions[questionIndex].required) {
            const active = Number(questionIndex);

            Toast({
              type: "fail",
              message: `第${active + 1}题为必选题`,
            });
            this.active = active;
            this.$refs.questionsTab.scrollTo(active);

            return;
          }
        }
      }

      const result = await UserQuestionAnswerService.addUserQuestionAnswers(
        this.user,
        this.questionAnswers.filter((answer) => !!answer)
      );

      console.log(result);

      const payload = this.questionAnswers.reduce((pre, cur) => {

        console.log(cur);

        if (cur.question.key) {
          pre[cur.question.key] = cur.value || "";
        }
        return pre;
      }, {});

      console.log(payload);

      const params = this.visitRecord;

      params.properties = {
        ...params.properties,
        ...payload,
      };

      params.properties.applicationId = this.application.id;
      params.properties.questionnaires.push(this.questionnaireId);
      params.progress = "REGISTERED";

      await UserVisitRecordService.updateUserVisitRecord(this.user, params);
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      // try {
      //   await this.getVisitRecord();

      //   const visitRecord = this.visitRecord;

      //   if (
      //     visitRecord.properties.questionnaires &&
      //     visitRecord.properties.questionnaires.length > 0
      //   ) {
      //     const questionnaires = visitRecord.properties.questionnaires;

      //     if (!questionnaires.includes(this.userQuestionnaireId)) {
      //       window.$router.push("/info");
      //     }

      //     if (questionnaires.includes(this.questionnaireId)) {
      //       window.$router.push("/card");
      //     }
      //   }
      // } catch (error) {
      //   console.log(error);

      //   window.$router.push("/info");
      // }

      this.questionnaire = await QuestionnaireService.getQuestionnaire({
        id: this.questionnaireId,
      });

      const questions = await window.axios({
        url: `/questionnaire/${this.questionnaireId}/question`,
        method: "GET",
      });

      this.questions = (questions || []).map((question) => {
        switch (question.type) {
          case "MULTIPLE_CHOICE":
            question.value = [];
            break;
          case "ONE_CHOICE":
            question.value = [];
            break;
          default:
            question.value = "";
            break;
        }

        return question;
      });

      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style lang="less" module>
.tip {
  background: #ffebdf;
  font-size: 14px;
  padding: 15px;
  color: #ff720a;

  a {
    font-size: 14px;
    color: #ff720a;
  }
}

.title {
  > h6 {
    font-size: 17px;
    font-weight: 600;
    color: #333333;
    line-height: 17px;
    text-align: center;
    margin: 15px 0;
  }
  > p {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 12px;
  }
}

.toolbar {
  padding: 15px;
}

.questions {
}

.question {
  padding: 0 16px;

  .content {
    margin: 20px 0;
    font-size: 16px;
    font-weight: 400;
    color: #314575;
  }
}
</style>